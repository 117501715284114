import OSS from "ali-oss";
let client = new OSS({
  region: "oss-accelerate",
  accessKeyId: "LTAIAjMeyelGoGr9",
  accessKeySecret: "R9xf04YBqV2B7KvN8hZYb2iRWpNTdc",
  bucket: "5gcoolwork",
  secure: true
});
let fileSaveCheckpoint = {};
const filePartSize = 1024 * 1024;
const fileTypeVail = /\.[^.]+$/;
let progressInfo = null;
let parseUploadOptions = () => {
  return {
    async progress(p, checkpoint) {
      fileSaveCheckpoint[checkpoint.fileSize] = checkpoint;
      progressInfo.scale = Math.floor(p * 100);
    },
    parallel: 1,
    partSize: filePartSize * 10
  };
};
let uploadOptions = parseUploadOptions();
let dateImprint = () => {
  return `${new Date().getTime().toString(32)}${Math.floor(
    Math.random() * 10000
  )}`;
};

let aliyunPut = async (file, loadInfo = {}) => {
  progressInfo = loadInfo;
  try {
    let fileName = dateImprint() + (fileTypeVail.exec(file.name) || "");
    let result;
    if (file.size < filePartSize * 10) {
      result = await client.put(fileName, file);
    } else {
      let currentCheckpoint = fileSaveCheckpoint[file.size];
      if (currentCheckpoint && currentCheckpoint.fileSize === file.size) {
        uploadOptions.checkpoint = currentCheckpoint;
        fileName = uploadOptions.checkpoint.name;
      } else {
        uploadOptions = parseUploadOptions(fileName);
      }
      result = await client.multipartUpload(fileName, file, uploadOptions);
      delete fileSaveCheckpoint[file.size];
    }
    return result;
  } catch (e) {
    return e;
  }
};

let aliyunPutStream = async (name, fileBlob, back, catalog = "") => {
  try {
    let fileName = catalog + dateImprint() + (fileTypeVail.exec(name) || "");
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileBlob);
    reader.onload = async event => {
      let file = await client.put(
        fileName,
        new OSS.Buffer(event.target.result),
        {
          "content-disposition": `attachment;filename=${name}`
        }
      );
      back(file);
    };
  } catch (err) {
    console.error(err);
  }
};

let aliyunPutThumb = async (name, fileBlob, back) => {
  try {
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileBlob);
    reader.onload = async event => {
      let file = await client.put(name, new OSS.Buffer(event.target.result), {
        "content-disposition": `attachment;filename=${name}`
      });
      back(file);
    };
  } catch (err) {
    console.error(err);
  }
};

let aliyunStop = () => {
  client.cancel();
};

let aliyunDelete = async name => {
  try {
    let result = await client.delete(name);
    console.log(result);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

let ossDownload = (name, response) => {
  return client.signatureUrl(name, {
    response
  });
};

export {
  aliyunPut,
  aliyunPutStream,
  aliyunPutThumb,
  aliyunStop,
  aliyunDelete,
  ossDownload
};
